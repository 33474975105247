// Scss Document
@charset "utf-8";
@use 'setting';


// バナー一覧 3カラム
.bnr-list-3column{
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  padding: 0 10px 10px;
  border-bottom: 1px solid setting.$border-color;
  a{
    &:hover{
      color: setting.$point-color;
    }
  }
  li{
    width: 240px;
    height: 80px;
    padding: 10px 20px;
    margin-bottom: 15px;
    border: 1px solid setting.$border-color;
    @media screen and (max-width: 800px){
      width: 49%;
    }
    @media screen and (max-width: 600px){
      height: 60px;
    }
    .bnr-item{
      width: 100%;
      height: 100%;
      background: setting.$main-bg-color;
      overflow: hidden;
      align-items: center;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &::after{
    content:"";
    display: block;
    width: 240px;
  }
}
// バナー一覧 4カラム
.bnr-list-4column{
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  padding: 0 10px 10px;
  border-bottom: 1px solid setting.$border-color;
  a{
    &:hover{
      color: setting.$point-color;
    }
  }
  li{
    width: 180px;
    height: 120px;
    padding: 10px 20px;
    margin-bottom: 15px;
    border: 1px solid setting.$border-color;
    background: setting.$main-bg-color;
    @media screen and (max-width: 800px){
      width: 49%;
      height: 100px;
    }
    .bnr-item{
      width: 100%;
      height: 100%;
      overflow: hidden;
      align-items: center;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .d-will{
    background: #04003b!important;
  }
  &::before{
    content:"";
    display: block;
    width: 180px;
    order: 1;
  }
  &::after{
    content:"";
    display: block;
    width: 180px;
  }
}

// アプリ一覧
.apps-list{
  display: flex;
  justify-content: start;
  flex-flow: wrap;
  padding: 0 10px 10px;
  border-bottom: 1px solid setting.$border-color;
  margin-bottom: 10px;
  @media screen and (max-width: 800px){
    justify-content: space-between;
  }
  a{
    &:hover{
      color: setting.$point-color;
    }
  }
  li{
    width: 32%;
    margin-right: 1%;
    border-bottom: 1px solid setting.$border-color;
    @media screen and (max-width: 800px){
      width: 100%;
      border: none;
    }
    .apps-item{
      display: flex;
      justify-content: space-between;
      flex-flow: nowrap;
      align-items: center;
      padding: 10px 0;
      @media screen and (max-width: 800px){
        padding: 0 0 10px 0;
      }
      .apps-image{
        border: 1px solid setting.$border-color;
        border-radius: 20px;
        width: 100px;
        height: 100px;
        overflow: hidden;
        margin: 0 auto;
        @media screen and (max-width: 800px){
          margin-left: 0;
        }
        @media screen and (max-width: 600px){
          width: 80px;
          height: 80px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .apps-contents{
        width: calc(100% - 100px - 10px);
        padding: 0 5px;
        margin: 5px auto 0;
        @media screen and (max-width: 800px){
          margin-left: 0;
        }
        @media screen and (max-width: 600px){
          width: calc(100% - 80px - 10px);
        }
        .apps-title{
          font-family: setting.$main-font;
          font-size: 1.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        }
        .apps-excerpt{
          font-size: 1.2rem;
          margin-top: 5px;
          color: setting.$sub-color;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          overflow: hidden;
          @media screen and (max-width: 800px){
            -webkit-line-clamp: 2;
            line-clamp: 2;
          }
          &:hover{
            color: setting.$point-color;
          }
        }
      }
    }
    &:nth-child(3){
      @media screen and (max-width: 800px){
        border-bottom: 1px solid setting.$border-color;
      }
    }
    &:nth-child(n+4){
      width: 100px;
      border-bottom: none;
      margin-right: 26px;
      margin-top: 10px;
      @media screen and (max-width: 800px){
        margin-right: 0;
        min-width: 25%;
        max-width: 25%;
      }
      @media screen and (max-width: 600px){
        width: 80px;
      }
      .apps-item{
        flex-flow: column;
        .apps-contents{
          width: 100%;
          @media screen and (max-width: 800px){
            width: 100px;
          }
          @media screen and (max-width: 600px){
            width: 80px;
          }
          .apps-title{
            line-height: 1.2;
            font-size: 1.1rem;
            text-align: center;
            display: block;
            white-space: normal;
            overflow: visible;
            text-overflow: visible;
            width: 100%;
          }
          .apps-excerpt{
            display: none;
          }
        }
      }
    }
  }
}

