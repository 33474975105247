// Scss Document
@charset "utf-8";
@use './setting';

/*************
    Design
*************/
:root {
  color-scheme: light !important;
}
html {font-size: 62.5%;
  &.fixed {
  width: 100%;
  height: 100%;
  position: fixed;
  }}
body{
  font-family: setting.$text-font;
  font-size: 1.4rem;
  line-height: 1.75;
  color: setting.$main-color;
  background-color: white !important;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  transition: all .2s;
  &:hover{
    color: setting.$point-color;
  }
  img:hover{
    opacity: 0.8;
  }
}
/* parts */
h1,h2,h3,h4,h5,h6{
  font-family: setting.$main-font;
  line-height: 1.5;
  span{
    display: block;
    font-family: setting.$sub-font;
    color: setting.$sub-color;
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  a{
    color: setting.$main-color;
  }
}
h1{ font-size: 3.0rem;}
h2{ font-size: 2.5rem;}
h3{ font-size: 2.0rem;}
h4{ font-size: 1.8rem;}
@media screen and (max-width: 800px){
  h1{ font-size: 2.8rem;}
  h2{ font-size: 2.2rem;}
  h3{ font-size: 1.8rem;}
  h4{ font-size: 1.6rem;}
}
.headline-top,
.headline-top-sub,
.headline-catecory{
  h2,h3{
    text-align: center;
  }
}
.headline-top{
  margin-bottom: 10px;
  span{
    margin-top: 10px; 
  }
}
.webp .headline-top{ background: top 22px center / 100% 20px no-repeat url("#{$url_images}/bg_headline.webp"); }
.no-webp .headline-top{ background: top 22px center / 100% 20px no-repeat url("#{$url_images}/bg_headline.png"); }
.headline-top-sub{
  margin: 30px 0 20px;
  padding-bottom: 15px;
  span{
    margin-top: 2px;
    font-size: 1.0rem;
  }
}
.webp .headline-top-sub{ background: bottom center / 300px 10px no-repeat url("#{$url_images}/bg_headline.webp"); }
.no-webp .headline-top-sub{ background: bottom center / 300px 10px no-repeat url("#{$url_images}/bg_headline.png"); }
.headline-catecory{
  min-height: 100px;
  padding: 10px;
  .catecory-contents{
    min-height: 80px;
    background: rgba(255,255,255,0.75);
    box-shadow: setting.$box-shadow;
    display: flex;
    align-items: center;
    flex-flow: nowrap;
    justify-content: space-between;
    @media screen and (max-width: 800px){
      padding: 15px;
    }
    @media screen and (max-width: 600px){
      flex-flow: column;
    }
    .category-title{
      min-width: 260px;
      width: 260px;
      border-right: 1px solid setting.$border-color;
      white-space: nowrap;
      letter-spacing: -0.05rem;
      @media screen and (max-width: 800px){
        min-width: 180px;
        width: 180px;
      }
      @media screen and (max-width: 600px){
        border-right: none;
        border-bottom: 1px solid setting.$border-color;
        width: 100%;
        padding-bottom: 10px;
        letter-spacing: 0;
      }
    }
    .catecory-excerpt{
      padding: 0 15px;
      font-size: 1.2rem;
      color: setting.$sub-color;
      @media screen and (max-width: 600px){
        padding: 10px 0 0;
      }
    }
  }
}
.webp .headline-catecory{ background: top center / cover no-repeat url("#{$url_images}/bg_headline.webp"); }
.no-webp .headline-catecory{ background: top center / cover no-repeat url("#{$url_images}/bg_headline.png"); }
@media screen and (max-width: 600px){
  .webp .headline-catecory{ background: top center / 100% calc(100% - 20px) no-repeat url("#{$url_images}/bg_headline.webp"); }
  .no-webp .headline-catecory{ background: top center / 100% calc(100% - 20px) no-repeat url("#{$url_images}/bg_headline.png"); }
}
.headline-catecory + .article-list{
  margin-top: 10px;
}
.headline-side{
  padding-bottom: 10px;
  h3{
    position: relative;
    span{
      display: inline-block;
      text-align: right;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.webp .headline-side{ background: bottom center / 100% 5px no-repeat url("#{$url_images}/bg_headline.webp"); }
.no-webp .headline-side{ background: bottom center / 100% 5px no-repeat no-repeat url("#{$url_images}/bg_headline.png"); }

.l2-space{
  &:first-letter{
    letter-spacing: 0.75rem !important;
  }
  a{
    &:first-letter{
      letter-spacing: 0.75rem !important;
    }
  }
  span{
    &:first-letter{
      letter-spacing: 0;
    }
  }
}
.btn,
.btn_large,
.btn_small,
.btn-moon{
  font-family: setting.$main-font;
  cursor: pointer;
  font-weight: 700;
}
.btn,
.btn_large{
  display: block;
  margin: 20px auto 0;
  position: relative;
  a {
    display: block;
    color: setting.$main-color;
    background: transparent;
    border: 2px solid setting.$main-color;
    position: relative;
    z-index: 1;
    transition: .2s;
    text-align: center;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: setting.$main-color;
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform ease .4s;
    }
    &:after{
      content: "";
      background-color: setting.$main-color;
      width: 8px;
      height: 10px;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      transition: transform all .8s;
    }
    &:hover {
      color: setting.$main-bg-color;
      &::before {
        transform-origin: 0% 50%;
        transform: scaleX(1);
      }
      &:after{
        background-color: setting.$main-bg-color;
        right: 10px;
      }
    }
  }
}
.btn{
  width: 370px;
  max-width: 100%;
  font-size: 1.6rem;
  @media screen and (max-width: 400px){
    width: 80%;
    max-width: 370px;
  }
  a{
    padding: 10px 0;
  }
}
.btn_large{
  width: 80%;
  font-size: 1.8rem;
  @media screen and (max-width: 400px){
    width: 100%;
  }
  a{
    padding: 15px 0;
  }
}
.btn_small{
  font-size: 1.4rem;
  text-align: right;
  padding-bottom: 12px;
  padding-right: 15px;
  margin-top: 10px;
  a{
    position: relative;
    display: inline-block;
    padding: 0 10px 0 0;
    color: setting.$main-color;
    text-decoration: none;
    outline: none;
    &:before,&::after{
      content: '';
      position: absolute;
      transition: all .3s;
    }
    &:before{
      bottom:-6px;
      left: 0;  
      width: 100%;
      height: 2px;
      background:setting.$main-color;
    }
    &:after{
      bottom:-5px;
      right:0; 
      width: 8px;
      height: 8px;
      border: 4px solid;
      border-color: transparent transparent setting.$main-color setting.$main-color;
    }
    &:hover {
      color: inherit;
      &::before {
        left: 8px;
      }
      &:after{
        right: -8px;
      }
    }
  }
}
@media screen and (max-width: 800px){
  .slider + .btn,
  .slider + .btn_large,
  .slider + .btn_small {
    margin: 50px auto 0;
  }
}
/* layout */
#container{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.inner{
  max-width: 1080px;
  margin: 0 auto;
}
#wrapper{
  width: 100%;
  height: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 30px;
  flex-grow: 1;
}
#main{
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-flow: nowrap;
  position: relative;
}
#contents{
  flex-grow: 1;
  max-width: 780px;
  width: 100%;
  height: 100%;
}
#side{
  width: 240px;
  height: auto;
}
.copy{
  margin: 5px auto;
  font-family: setting.$main-font;
  text-align: center;
}
.sp-none{
  display: block;
}
.pc-none{
  display: none;
}

@media screen and (max-width: 800px){
  .inner{
    max-width: 100%;
    margin: 0;
  }
  #wrapper{
    padding: 60px 0 0 0;
    max-width: 100%;
    margin: 0;
  }
  #main{
    width: 100%;
    flex-flow: column;
  }
  #side{
    width: 100%;
    margin-top: 30px;
  }
  .sp-none{
    display: none;
  }
  .pc-none{
    display: block;
  }
}
/* header */
header{
  height: 138px;
  background: setting.$main-bg-color;
  position: relative;
  @media screen and (max-width: 800px){
    height: 155px;
  }
  .inner{position: relative;}
  h1{
    font-family: setting.$moon-font;
    padding: 15px 0 15px 70px;
    font-size: 3.5rem;
    line-height: 1;
    letter-spacing: -3px;
    position: relative;
    vertical-align: middle;
    @media screen and (max-width: 800px){
      height: 80px;
      padding: 10px 0 5px 67px;
      font-size: 2.8rem;
    }
    span{
      text-transform: none;
      font-family: inherit;
      color: setting.$moon-main-color;
      display: inline-block;
      letter-spacing: 0;
      font-size: 1.6rem;
    }
    .subtitle{
      line-height: 1.5;
      font-size: 1.3rem;
      color: setting.$moon-point-color;
      letter-spacing: 0px;
      padding-left: 0.5rem;
      @media screen and (max-width: 800px){
        font-size: 1.2rem;
      }
    }
    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 67px;
      height: 67px;
      background: center / contain no-repeat url(#{$url_images}/icon.svg);
      @media screen and (max-width: 800px){
        top: 2px;
      }
    }
  }
  .copy{
    @media screen and (max-width: 800px){
      text-align: left;
      font-size: 1.2rem;
      padding-left: 10px;
    }
  }
  #search-pc{
    position: absolute;
    width: 25%;
    bottom: 10px;
    right: 0;
    @media screen and (max-width: 800px){
      display: none;
    }
  }
  #search-sp{
    display: none;
    @media screen and (max-width: 800px){
      display: block;
      position: relative;
      width: 100%;
      margin: 20px 0 10px;
      padding: 0 10px;
    }
  }
  &::after {
    content: '';
    background-color: setting.$moon-bg-color;
    background-image: setting.$moon-pattern-stripe !important;
    background-size: 18px;
    position: absolute;
    right: 0;
    top: 75px;
    left: 0;
    height: 20px;
    @media screen and (max-width: 800px){
      top: 72px;
    }
  }
}
//  mv
// .webp header{ background: top center / cover no-repeat url("#{$url_images}/bg_header.webp"); }
// .no-webp header{ background: top center / cover no-repeat url("#{$url_images}/bg_header.png"); }
#main-visual{
  max-width: 1080px;
  margin: 0 auto;
//  background: top right / auto 270px no-repeat url("#{$url_images}/illust-header.png");
  @media screen and (max-width: 800px){
//    background: top right / auto 170px no-repeat url("#{$url_images}/illust-header.png");
  }
}
#logo{
  width: 300px;
  margin: 0 auto;
  display: none;
  @media screen and (max-width: 800px){
    width: 170px;
  }
}
/* global-navi */
#global-navi{
  position: relative;
  background: setting.$moon-bg-color;
  //box-shadow: setting.$box-shadow;
  z-index: 999;
  ul {
    list-style-type: none;
    width: 100%;
    height: 60px;
    margin: 0;
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-right: 25%;
    @media screen and (max-width: 800px){
      padding: 10px 0;
      height: 48px;
      align-items: stretch;
    }
    li{
      position: relative;
      width: calc(100% - 25% - 70px / 5);
      @media screen and (max-width: 800px){
        width: calc(100% - 70px / 5);
      }
      b.sp-none{
        display: inline;
        font-weight: normal;
      }
      @media screen and (max-width: 1080px){
        b.sp-none{
          display: none;
        }
      }
      &::after{
        content: "";
        display: block;
        height: 40px;
        width: 1px;
        background: setting.$border-color;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        @media screen and (max-width: 800px){
          height: 30px;
        }
      }
      a {
        display: block;
        width: 100%;
        padding: 0 10px;
        font-family: setting.$moon-font;
//        color: setting.$main-color;
        color: setting.$moon-main-color;
        font-size: 1.6rem;
        text-decoration: none;
        text-align: center;
        white-space: nowrap;
        @media screen and (max-width: 800px){
          font-size: 1.4rem;
          line-height: 20px;
        }
        span{
          display: block;
          margin-top: -2px;
          height: 10px;
          color: setting.$moon-point-color;
          font-size: 1rem;
          text-transform: uppercase;
          @media screen and (max-width: 800px){
            transform: scale(.9);
          }
        }
        &:hover{
//          color: setting.$point-color;
          color: setting.$moon-point-color;
          span{
            color: inherit;
          }
        }
      }
      &:first-child{
        text-align: center;
        width: 70px;
        @media screen and (max-width: 800px){
          width: 50px;
        }
        a {
          padding: 2px 20px 0;
          @media screen and (max-width: 800px){
            padding: 0 10px 0 15px;
          }
          span{
            @media screen and (max-width: 800px){
              margin-left: -4px;
            }
          }
        }
        img{
          margin: 0 auto;
          @media screen and (max-width: 800px){
            width: 20px;
            height: auto;
          }
        }
        &::before {
          font-family: "icomoon";
          content: "\e904";
          display: block;
          font-size: 2.2rem;
          line-height: 1.25;
          @media screen and (max-width: 800px){
            font-size: 1.8rem;
            margin-top: -2px;
          }
        }
      }
    }
  }
}
//  固定
.fixed {
    position: fixed;
    top: 0;
    padding: 0;
    width: 100%;
    z-index: 9999;
}
//  検索フォーム
  .search-form {
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid setting.$border-color;
    border-radius: 3px;
    input {
      width: 100%;
      height: 40px;
      padding: 5px 10px 5px 5px;
      border: none;
      box-sizing: border-box;
      font-size: 1.4rem;
      outline: none;
      background: setting.$sub-bg-color;
      &::placeholder {
        color: setting.$sub-color;
        @media screen and (max-width: 800px){
          font-size: 1.2rem;
        }
      }
      @media screen and (max-width: 600px){
        height: 30px;
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 40px;
      border: none;
      background: setting.$sub-bg-color;
      cursor: pointer;
      @media screen and (max-width: 600px){
        width: 35px;
        height: 30px;
      }
      &::before {
        content: '';
        width: 23px;
        height: 23px;
        background: center / contain no-repeat url('#{$url_images}/icon_search.png');
        background-repeat: no-repeat;
        @media screen and (max-width: 600px){
          width: 18px;
          height: 18px;
        }
      }
    }
    label{
      width: 100%;
    }
  }

/* main */
section{
  margin: 0 0 60px;
  animation-name: displayAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes displayAnime{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* tab */
.tab{
  display: flex;
  flex-wrap: nowrap;
  li{
    flex: 1;
    text-align: center;
    position: relative;
    a{
      display: block;
      background: inherit;
      margin: 0 2px;
      padding: 0 0 15px;
      font-size: 2.0rem;
      color: setting.$sub-color;
      font-family: setting.$main-font;
      cursor: pointer;
      span{
        display: block;
        font-size: 1.2rem;
        font-family: setting.$sub-font;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    &::after{
      content: "";
      width: 100%;
      height: 10px;
      background: setting.$border-color;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &.active{
      a{
        color: setting.$main-color;
      }
      &::after{
        background: center / cover no-repeat url('#{$url_images}/bg_headline.png');
      }
    }
  }
  h2{
    font-size: 2.0rem;
  }
}
.area {
  display: none;  /*はじめは非表示*/
  opacity: 0;  /*透過0*/
  background: #fff;
  padding: 20px 0 0;
}
.area.is-active {
    display: block;  /*表示*/
    animation-name: displayAnime;  /*ふわっと表示させるためのアニメーション*/
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
// タブの記事一覧
.article-list-2column{
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  border-top: 1px solid setting.$border-color;
  flex-flow: wrap;
  a{
    &:hover{
      color: setting.$point-color;
    }
  }
  li{
    width: 49%;
    padding: 10px 0;
    border-bottom: 1px solid setting.$border-color;
    @media screen and (max-width: 600px){
      width: 100%;
    }
    .article-item{
      display: flex;
      justify-content: space-between;
      flex-flow: nowrap;
      padding: 0;
      .article-image{
        border: 1px solid setting.$border-color;
        width: 45%;
        aspect-ratio: 32 / 21;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .article-contents{
        width: calc(100% - 45% - 10px);
        margin-top: 0;
        position: relative;
        padding: 0 0 25px;
        @media screen and (max-width: 800px){
          padding-bottom: 45px;
        }
        @media screen and (max-width: 600px){
          padding-bottom: 25px;
        }
        .article-title{ 
          font-weight: 700;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          overflow: hidden;
          @media screen and (max-width: 600px){
            -webkit-line-clamp: 3;
            line-clamp: 3;
            line-height: 1.8;
          }
          @media screen and (max-width: 400px){
            -webkit-line-clamp: 2;
            line-clamp: 2;
          }
        }
        .article-excerpt{
          display: none;
        }
        .article-info{
          margin-top: 10px;
          display: flex;
          align-items: center;
          flex-flow: nowrap;
          justify-content: space-between;
          font-family: setting.$sub-font;
          width: 100%;
          position: absolute;
          bottom: 0;
          padding-right: 10px;
          @media screen and (max-width: 800px){
            flex-flow: wrap;
            justify-content: start;
          }
          @media screen and (max-width: 600px){
            justify-content: space-between;
          }
          .article-category{
            padding: 0 8px;
            border-radius: 2px;
            border: 1px solid setting.$border-color;
            color: setting.$main-color;
            font-size: 1rem;
            white-space: nowrap;
          }
          .article-mark{
            display: none;
          }
          .article-date{
            color: setting.$sub-color;
            font-size: 1.2rem;
            @media screen and (max-width: 800px){
              width: 100%;
              text-align: right;
            }
            @media screen and (max-width: 600px){
              width: auto;
            }
          }
        }
      }
    }
  }
}
#tab-new{
  .article-list-2column{
    li{
      .article-item{
        .article-contents{
          .article-info{
            .article-mark{
              display: inline;
              font-weight: 600;
              color: setting.$point-color;
              font-size: 1.2rem;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
}
#tab-popular{
  .article-list-2column{
    li{
      width: 49%;
      @media screen and (max-width: 600px){
        width: 100%;
      }
      &:first-child,
      &:nth-child(2){
        width: 100%;
        .article-item{
          .article-image{
            width: 49%;
            @media screen and (max-width: 600px){
              width: 45%;
            }
          }
          .article-contents{
            width: 49%;
            @media screen and (max-width: 600px){
              width: calc(100% - 45% - 10px);
            }
            .article-title{
              margin-top: 15px;
              font-size: 1.8rem;
              @media screen and (max-width: 600px){
                font-size: 1.4rem;
              }
            }
            .article-excerpt{
              margin-top: 15px;
              color: setting.$sub-color;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              overflow: hidden;
              @media screen and (max-width: 600px){
                display: none;
              }
            }
          }
        }
      }
      &:hover{
        color: setting.$point-color;
        .article-item .article-contents .article-excerpt{
          color: setting.$point-color;
        }
      }
      &:nth-child(2){
        .article-item{
          flex-flow: row-reverse;
          @media screen and (max-width: 600px){
            flex-flow: row;
          }
        }
      }
    }
  }
}
// ランキング一覧（スクエア）
.article-list-square{
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  padding: 0 10px;
  border-top: 1px solid setting.$border-color;
  a{
    &:hover{
      color: setting.$point-color;
    }
  }
  li{
    width: 49%;
    padding: 10px 0;
    border-bottom: 1px solid setting.$border-color;
    @media screen and (max-width: 600px){
      width: 100%;
    }
    .article-item{
      display: flex;
      justify-content: space-between;
      flex-flow: nowrap;
      padding: 0;
      align-items: center;
      .article-image{
        border: 1px solid setting.$border-color;
        width: 100px;
        aspect-ratio: 1 / 1;
        overflow: hidden;
        @media screen and (max-width: 600px){
          width: 80px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .article-contents{
        width: calc(100% - 100px - 15px);
        margin-top: 0;
        @media screen and (max-width: 600px){
          width: calc(100% - 80px - 15px);
        }
        .article-title{
          font-weight: 700;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}
// 記事一覧（3カラム）
.article-list{
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  padding: 0 10px;
  @media screen and (max-width: 800px){
    padding: 0;
  }
  a{
    &:hover{
      color: setting.$point-color;
    }
  }
  li{
    width: 32%;
    padding: 10px 0;
    border-bottom: 1px solid setting.$border-color;
    @media screen and (max-width: 800px){
      width: 49%;
    }
    .article-item{
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      .article-image{
        border: 1px solid setting.$border-color;
        width: 100%;
        aspect-ratio: 32 / 21;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .article-contents{
        margin-top: 10px;
        padding: 0 10px;
        min-height: 80px;
        display: flex;
        justify-content: space-between;
        flex-flow: column;
        .article-title{
          font-weight: 700;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          @media screen and (max-width: 600px){
            height: 50px;
            -webkit-line-clamp: 2;
            line-clamp: 2;
          }
        }
        .article-excerpt{
          display: none;
        }
        .article-info{
          margin-top: 10px;
          display: flex;
          align-items: center;
          flex-flow: nowrap;
          justify-content: space-between;
          font-family: setting.$sub-font;
          .article-category{
            padding: 0 8px;
            border-radius: 2px;
            border: 1px solid setting.$border-color;
            color: setting.$main-color;
            font-size: 1rem;
          }
          .article-mark{
            display: none;
          }
          .article-date{
            color: setting.$sub-color;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  &::after{
    content:"";
    display: block;
    width: 240px;
  }
}

//  メディア掲載・お知らせの記事一覧
#information-area{
  .article-list{
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    padding: 0;
    li{
      width: 100%;
      padding: 10px 30px 10px 10px;
      border-bottom: 1px solid setting.$border-color;
      position: relative;
      .article-item{
        display: flex;
        justify-content: space-between;
        flex-flow: nowrap;
        align-items: center;
        @media screen and (max-width: 600px){
          align-items: flex-start;
          flex-flow: column;
        }
        .article-title{
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @media screen and (max-width: 600px){
            margin-top: 5px;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
        .article-date{
          width: 90px;
          padding-right: 15px;
          color: setting.$sub-color;
          font-size: 1.2rem;
        }
        &:hover{
          color: setting.$point-color;
        }
      }
      &:first-child{
        border-top: 1px solid setting.$border-color;
      }
      &:after{
        content: "";
        background-color: setting.$main-color;
        width: 8px;
        height: 10px;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        transition: all .3s;
      }
      &:hover {
        color: setting.$point-color;
        .article-item .article-date{
          color: setting.$point-color;
        }
        &:after{
          background-color: setting.$point-color;
          right: 10px;
        }
      }
    }
  }
}
/* side */
#side{
  .sidebar-inner{
    max-width: 100% !important;
  }
  section{
    margin-bottom: 20px;
  }
  @media screen and (max-width: 800px){
    margin: 0;
    padding: 30px 10px 0;
    background: #f9f9f9;
  }
  // サイドバーのバナー一覧
  .bnr-list{
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    padding: 0 0 10px 0;
    a{
      &:hover{
        color: setting.$point-color;
      }
    }
    li{
      width: 100%;
      margin-top: 10px;
      @media screen and (max-width: 800px){
        width: 80%;
        max-width: 320px;
        margin: 10px auto 0;
      }
      .bnr-item{
        border: 1px solid setting.$border-color;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
  // サイドバーの記事一覧（ランキング）
  .article-list{
    border-top: 1px solid setting.$border-color;
    flex-flow: column;
    padding: 0 0 10px 0;
    li{
      width: 100%;
      .article-item{
        position: relative;
        @media screen and (max-width: 800px){
        display: flex;
        justify-content: space-between;
        flex-flow: nowrap;
        padding: 0;
        }
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: -5px;
          width: 30px;
          height: 30px;
          background: rgba(245,85,84,.7);
          text-align: center;
          font-family: setting.$main-font;
          color: setting.$main-bg-color;
          font-size: 1.8rem;
          z-index: 1;
          @media screen and (max-width: 800px){
            left: 0;
          }
        }
        .article-image{
          @media screen and (max-width: 800px){
            width: 45%;
            aspect-ratio: 32 / 21;
          }
        }
        .article-contents{
          padding: 0 5px;
          @media screen and (max-width: 800px){
            width: calc(100% - 45% - 10px);
            margin-top: 0;
            position: relative;
            padding: 0;
          }
          .article-title{
            @media screen and (max-width: 800px){
              -webkit-line-clamp: 3;
            }
          }
        }
      }
      &:first-child{
        .article-item::before{
          content: "1";
        }
      }
      &:nth-child(2){
        .article-item::before{
          content: "2";
        }
      }
      &:nth-child(3){
        .article-item::before{
          content: "3";
        }
      }
      &:nth-child(4){
        .article-item::before{
          content: "4";
        }
      }
      &:nth-child(5){
        .article-item::before{
          content: "5";
        }
      }
    }
  }
  // サイドバーの専門家ー一覧
  .specialist-list{
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
    padding: 0 0 10px 0;
    a{
      &:hover{
        color: setting.$point-color;
      }
    }
    li{
      width: 49%;
      padding: 10px 0 0;
      @media screen and (max-width: 800px){
        width: 33%;
      }
      .specialist-item{
        text-align: center;
        .specialist-image{
          margin: 0 auto;
          border: 1px solid setting.$border-color;
          width: 80px;
          height: 80px;
          border-radius: 80px;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .specialist-title{
          margin-top: 5px;
          color: setting.$sub-color;
          font-size: 1.2rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
        }
        .specialist-name{
          font-family: setting.$main-font;
          font-size: 1.6rem;
          margin-top: -5px;
        }
      }
    }
  }
  .tw{
    display: block;
    width: 100%;
    max-height: 320px; 
    height: 320px;
    padding: 10px 0;
    margin-bottom: 10px;
    .tw-wrap{
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 60%;
      iframe{
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.relative{
  position: relative !important;
  width: 100% !important;
  padding-bottom: 30px !important;
  transform: none !important;
}
/* footer */
footer{
  margin-top: 120px;
  min-height: 640px;
  padding: 60px 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: setting.$moon-bg-color;
  position: relative;
  @media screen and (max-width: 800px){
    margin-top: 60px;
    min-height: 100px;
    padding: 30px 0 5px;
  }
  a:hover{
    color: setting.$moon-point-color !important;
  }
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    display: block;
    width: 100%;
    background: #efeaf8;
    background-image: linear-gradient(90deg, #fffadf 50%, transparent 50%) !important;
    background-size: 18px;
    height: 10px;
  }
}
//.webp footer{ background: bottom center / cover no-repeat url("#{$url_images}/bg_footer.webp"); }
//.no-webp footer{ background: bottom center / cover no-repeat url("#{$url_images}/bg_footer.png"); }
#sitelist{
  @media screen and (max-width: 800px){
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px){
    margin-bottom: 80px;
  }
  .inner{
//    background: rgba(255,255,255,0.75);
//    box-shadow: setting.$box-shadow;
    padding: 10px 30px 30px;
    @media screen and (max-width: 800px){
      padding: 20px;
    }
    @media screen and (max-width: 800px){
      padding: 10px 10px 20px;
    }
  }
  ul.sitelist{
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-flow: nowrap;
    @media screen and (max-width: 600px){
      flex-flow: wrap;
    }
    li{
      width: 32%;
      max-width: 90%;
      @media screen and (max-width: 600px){
//        max-width: 180px;
      }
      @media screen and (max-width: 600px){
        width: 100%;
        max-width: 100%;
      }
      ul.sitelist-section{
        list-style: none;
        display: flex;
        flex-flow: column;
        img{
//          max-width: 200px;
          margin: 0 auto;
        }
        .subtitle{
          text-align: center;
        }
        li{
          width: 100%;
          margin-bottom: 10px;
          @media screen and (max-width: 800px){
            font-size: 1.4rem;
          }
          @media screen and (max-width: 600px){
            width: 100%;
            max-width: 100%;
          }
          a{
            color: setting.$moon-main-color;
          }
          h4{
            font-size: 1.6rem;
          }
          .btn-moon{
            margin-top: 20px;
            a{
              display: block;
              margin: 10px auto 0;
              text-align: center;
              width: 90%;
              max-width: 90%;
              padding: 10px;
              border: 1px solid setting.$moon-point-color;
              border-radius: 5px;
              color: setting.$moon-point-color;
            }
          }
        }
        .sitelist-logo{
          padding-top: 30px;
          @media screen and (max-width: 800px){
            padding-top: 20px;
          }
          img{
            width: 100%;
//            max-width: 200px;
          }
          .subtitle{
            margin-top: 10px;
            font-family: setting.$main-font;
          }
        }
        .text-area{
          background: setting.$main-bg-color;
          border-radius: 15px;
          padding: 15px;
          color: setting.$moon-main-color;
          h4{
            color: setting.$moon-point-color;
            margin-bottom: 10px;
          }
          p{
            font-size: 1.4rem;
            margin-bottom: 10px;
          }
          .under-line{
            font-family: setting.$main-font;
            font-weight: 700;
            background: linear-gradient(transparent 30%, #b6e9ee 30%);
          }
          .small{
            font-size: 1.2rem; 
          }
        }
        .sitelist-headline{
          padding: 30px 0 10px 10px;
          margin-bottom: 20px;
          position: relative;
          h3{
            position: relative;
            @media screen and (max-width: 800px){
              font-size: 1.6rem;
            }
            span{
              display: inline-block;
              text-align: right;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              color: #666;
              @media screen and (max-width: 600px){
                display: none;
              }
            }
          }
          &::before, &::after {
            content: "";
            position: absolute;
            bottom: 0;
            overflow: hidden;
          }
          &::before {
            border-bottom: 2px solid setting.$moon-border-color;
            width: 30%;
            left: 0;
          }
          &::after {
            border-bottom: 2px solid setting.$moon-border-color-sub;
            width: 70%;
            right: 0;
          }
        }
        .sitelist-item{
          padding: 0 10px 0 20px;
//          font-weight: 700;
          position: relative;
//          &::before{
//            content: "";
//            background-color: setting.$main-color;
//            width: 8px;
//            height: 10px;
//            clip-path: polygon(0 0, 0% 100%, 100% 50%);
//            position: absolute;
//            top: 50%;
//            left: 15px;
//            transform: translateY(-50%);
//            transition: all .3s;
//          }
        }
      }
    }
  }
}
/* slick */
.slick-dotted.slick-slider {
  margin-bottom: 50px;
}
.slider {
  .slick-slide {
    margin: 0 5px;
    position: relative;
    overflow: hidden;
  }
  .slick-arrow {
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 0;
    position: absolute;
    top: auto;
    bottom: -40px;
    z-index: 99;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: setting.$main-color;
      position: absolute;
      bottom:0;
    }
  }
  .slick-next,
  .slick-prev{
    &::after{
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border: 4px solid;
      position: absolute;
      bottom: 0;
    }
  }
  .slick-next{
    right: 10px;
    &::after{
      right: -1px;
      border-color: transparent transparent setting.$main-color setting.$main-color;
    }
  }
  .slick-prev{
    left: 10px;
    &::after{
      left: -1px;
      border-color: transparent setting.$main-color setting.$main-color transparent;
    }
  }
  .slick-dots {
    bottom: -20px;
    li {
      display: inline-block;
      width: 10px;
      height: 15px;
      border: none;
      button::before {
        display: none;
      }
    }
    button {
      display: block;
      width: 8px;
      height: 8px;
      margin: 8px;
      font-size: 0;
      padding: 0;
      border: 0;
      outline: none;
      cursor: pointer;
      background: setting.$sub-color;
      border-radius: 50%;
    }
  }
  .slick-active button {
    background: setting.$point-color;
  }
}

/* menu */
// オープンボタン
.openbtn{
  position: absolute;
  z-index: 10000;
  top: 5px;
  right: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: none;
  @media screen and (max-width: 800px){
    display: block;
  }
  span{
    display: inline-block;
    transition: all .4s;
    position: absolute;
    left: 14px;
    height: 3px;
    border-radius: 2px;
    background-color: setting.$moon-main-color;
    width: 50%;
//    box-shadow: setting.$box-shadow;
    &:nth-of-type(1) {
      top:10px; 
    }
    &:nth-of-type(2) {
      top:20px;
    }
    &:nth-of-type(3) {
      top:30px;
    }
  }
  &.active{

    span{
      &:nth-of-type(1) {
        top: 13px;
        left: 15px;
        transform: translateY(6px) rotate(-45deg);
        width: 50%;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3){
        top: 25px;
        left: 15px;
        transform: translateY(-6px) rotate(45deg);
        width: 50%;
      }
    }
  }
}
//メニュー
#menu{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: -120%;
  width:100%;
  height: 100vh;
  transition: all .5s;
  z-index: 9999;
  &::before{
    content: "";
    background: rgba(0, 0, 0, .75);
    width: 20%;
    height: 100vh;
    transition: all .5s;
    position: absolute;
    top: 0;
    left: -240%;
  }
  &.panelactive{
    right: 0;
    &::before{

      left: 0;
    }
  }
  #menu-list{ //ナビの数が増えた場合縦スクロール
    position: fixed;
    width: 80%;
    margin: 0 0 0 20%;
    height: 100%;
    background: setting.$main-bg-color;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 10000;
  }
  // メニューヘッダー
  #menu-header{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid setting.$border-color;
    padding: 5px;
    text-align: center;
    padding-right: 20px;
    img{
      margin: 0 auto;
    }
  }
  // セクション
  section{
    margin-bottom: 0;
    padding-top: 8px;
  }
  // アコーディオン
  ul.accordion-menu{
    list-style: none;
    width: 100%;
    max-width: 100%;
    .menu-title{
      cursor: pointer;
      font-family: setting.$main-font;
      font-size: 1.4rem;
      line-height: 1.5;
      padding: 0 15px 8px;
      position: relative;
      border-bottom: 1px solid setting.$border-color;
      /*アイコンの＋と×*/
      &::before,
      &::after{
        content:'';
        width: 15px;
        height: 2px;
        background-color: setting.$sub-color;
        position: absolute;
        right: 15px;
        top: 8px;
        transition: all .3s;
      }
      &::before{
        transform: rotate(0deg);
      }
      &::after{    
        transform: rotate(90deg);
      }
      &.close{
        &::after{
          opacity: 0;
        }
      }
    }
    .menu-box {
      display: none;
      background: #f9f9f9;
    }
    ul.menu-item-list{
      padding: 15px;
      border-bottom: 1px solid setting.$border-color;
      li{
        padding: 3px 0 3px 30px;
        position: relative;
        font-size: 1.2rem;
        &::before{
          content: "";
          background-color: setting.$sub-color;
          width: 8px;
          height: 10px;
          clip-path: polygon(0 0, 0% 100%, 100% 50%);
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }
      }
    }
  }
  .copy{
    text-align: center;
    color: setting.$sub-color;
    padding-top: 8px;
  }
}

// メニュー見出し
.headline-menu {
  position: relative;
  cursor: pointer;
  transition: all .5s ease;
  padding: 8px 10px 10px 15px;
  margin: 10px 0 0;
  font-family: setting.$main-font;
  font-size: 1.8rem;
  span{
    font-family: setting.$sub-font;
    color: setting.$sub-color;
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    display: inline-block;
    text-align: right;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after{
    content: "";
    background-color: setting.$main-color;
    width: 8px;
    height: 10px;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
.webp .headline-menu{ background: bottom center / 100% 5px no-repeat url("#{$url_images}/bg_headline.webp"); }
.no-webp .headline-menu{ background: bottom center / 100% 5px no-repeat no-repeat url("#{$url_images}/bg_headline.png"); }

/* returnto top */
#return-top{
  position: fixed;
  bottom: 50px;
  right: 0;
  text-align: center;
  height: 60px;
  width: 60px;
  img{
    margin: 0 auto;
  }
  span{
    font-size: 1rem;
    font-family: setting.$main-font;
    line-height: 1.2;
  }
}