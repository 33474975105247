/************* 
    Setting 
*************/
$main-font: "DNP ShueiMGoStd B", "dnp-shuei-mgothic-std", sans-serif;
$sub-font: "m-plus-rounded-1c", sans-serif;
$text-font: "Hiragino Sans","YuGothic","Meiryo",sans-serif;

$main-color: #333333;
$sub-color: #999999;
$point-color: #F55554;
$border-color: #DDDDDD;

$main-bg-color: #FFFFFF;
$sub-bg-color: #F9F9F9;

$box-shadow: 0 3px 6px rgba(0, 0, 0, .15);

$moon-font: fot-chiaro-std, sans-serif;
$moon-main-color: #55335c;
$moon-point-color: #e27da1;
$moon-point-color-sub: #fffadf;
$moon-border-color: #bfb5ef;
$moon-border-color-sub: #d8d3f3;
$moon-bg-color: #efeaf8;
$moon-pattern-stripe: linear-gradient(90deg, $moon-point-color-sub 50%, transparent 50%);

// icomoon
@font-face {
    font-family: 'icomoon';
    src:  url('../../assets/fonts/icomoon.eot');
    src:  url('../../assets/fonts/icomoon.eot') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf') format('truetype'),
    url('../../assets/fonts/icomoon.woff') format('woff'),
    url('../../assets/fonts/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    //speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

  /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-close:before {content:"\e90e";}
.icon-menu:before {content:"\e90f";}
.icon-instagram:before {content:"\e90d";}
.icon-hatebu:before {content:"\e90c";}
.icon-quotation:before {content:"\e909";}
.icon-line:before {content:"\e90a";}
.icon-pocket:before {content:"\e90b";}
.icon-calendar:before {content:"\e900";}
.icon-facebook:before {content:"\e901";}
.icon-folder:before {content:"\e902";}
.icon-google:before {content:"\e903";}
.icon-home:before {content:"\e904";}
.icon-rss:before {content:"\e905";}
.icon-search:before {content:"\e906";}
.icon-tag:before {content:"\e907";}
.icon-twitter:before {content:"\e908";}
.icon-sphere:before { content:"\e9c9";}

